@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --blue: "#6BA6FF";
  --blueHover: "#448FFF"
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.text-notosans-500 {
  font-family: "Noto Sans";
  font-weight: 500;
}

.text-standard-inter {
  font-family: "Inter";
  font-weight: 400;
}

.text-inter-500 {
  font-family: "Inter";
  font-weight: 500;
}

.dashboardContainer {
  height: calc(100vh - (61px + 55.5px));
}

.text-bold-inter {
  font-family: "Inter";
  font-weight: 600;
}

.text-inter-700 {
  font-family: "Inter";
  font-weight: 700;
}

.hidden-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}