.navbarMenu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    gap: 39px;
}

.navbarMenu li {
    float: left;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    text-decoration: none;
}

.navbarMenu li:hover {
    cursor: pointer;
    color: black;
}

.logout {
    padding: 0px;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}

.logout:hover {
    cursor: pointer;
}


.dashboardContainer {
    height: calc(100vh - (61px + 55.5px));
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 90px;
    gap: 20px;
}