.applicationTitle {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: "Noto Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.main {
    max-height: 100%;
}

.shadow {
    position: relative;
}

.shadow::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 85px;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F6F4F8 100%);
}


.sliding {
    position: absolute;
    top: -100%;
    transition: 1s;
}

.slideOut {
    animation: slideOut 0.7s forwards;
}

.slideIn {
    animation: slideIn 0.5s forwards;
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0.5;
    }

    100% {
        transform: translateY(0%);
    }
}


.header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #595959;
}

.tableText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
}

.leftBtn {
    border-radius: 6px 0px 0px 5px;
    border: 1px solid #E4E4E4;
    color: #000;
    text-align: justify;
    padding: 10px;

    /* text air2 */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.midBtn {
    border-top: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;
    padding: 10px;

    /* text air2 */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rightBtn {
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #E4E4E4;
    color: #000;
    text-align: justify;
    padding: 10px;

    /* text air2 */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.applicationSecondary {
    color: #595959;
    /* text air */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
}

.emptyState {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='20 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: #F4F4F4;
    padding: 35px 45px 45px 25px;
}

.emptyState p {
    color: #000;

    font-family: Comfortaa;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
}


.addPopup {
    background: #F9F9F9;
    padding: 27px 24px;
    border-radius: 7px;
    z-index: 3;
}

.openner {
    width: 5%;
}

.openner img {
    height: 20px;
    cursor: pointer;
    transition: transform 0.5s ease;
}

.info {
    width: 100%;
    background-color: red;
}

.rotate180 {
    transform: rotate(180deg);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}