@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');


.title {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mainTitle {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.secondaryTitle {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: space-between;
}

.formWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formWrapper label {
    color: #595959;
    /* text air */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.greyText {
    color: #595959;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    cursor: pointer;
}

.greyTextSmall {
    color: #595959;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    cursor: pointer;
}

.addText {
    color: var(--header-black, #434343);

    /* text air */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

input {
    width: 100%;
    height: 38px;
    align-self: center;
    border-radius: 7px;
    border: 0.5px solid #D7D7D7;
    background: #F9F9F9;
    margin-top: 5px;
    resize: none;
    padding-left: 8px;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.bt {
    height: 40px;
    border: none;
    color: white;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 7px;
}